<script setup lang="ts">
import iconRank_1 from '@/assets/images/list/icon_rank_top1.png'
import iconRank_2 from '@/assets/images/list/icon_rank_top2.png'
import iconRank_3 from '@/assets/images/list/icon_rank_top3.png'
import iconRank_4 from '@/assets/images/list/icon_rank_top4.png'
import iconLocation from '@/assets/icons/icon_location.png'
import iconGift from '@/assets/images/list/icon_gift.png'
import { useCommonStore } from '@/stores/common'
import { toThousands } from '@/utils'
import type { RankListAttrDto } from '~/apis/back/types'

interface Props {
  itemIndex: number
  itemData: RankListAttrDto
  hideRankNum?: boolean
  topChoice?: boolean
}

const props = defineProps<Props>()
const commonDataStore = useCommonStore()
const { t } = useI18n()

const { isBurnCoinsScene } = useGlobalStore()
const { navigatorToAttractionDetail } = useAttractionList()

const priceSymbol = commonDataStore.getCurrencySymbol
const isTop = computed(() => {
  return props.itemIndex < 3
})

const rangImg = computed(() => {
  return [
    iconRank_1,
    iconRank_2,
    iconRank_3,
    iconRank_4,
  ][props.itemIndex] || iconRank_4
})

const rankStyle = computed(() => {
  return {
    backgroundImage: `url(${rangImg.value})`,
    backgroundSize: '100% 100%',
  }
})

const priceText = computed(() => {
  return priceSymbol + toThousands(props.itemData.lowPrice!)
})

const rating = ref(props.itemData.rating ? Number.parseFloat(props.itemData.rating) : 0)
const ratingDesc = computed(() => {
  return props.itemData.rating
})

function clickItem() {
  navigatorToAttractionDetail(props, !props.hideRankNum)
}
</script>

<template>
  <div class="list-item" :style="topChoice ? { backgroundColor: 'var(--tc-color-primary)' } : null">
    <h4 v-if="topChoice" p="x-4 y-1" text="lg" font="600">
      {{ t('key.search.list.top.choice') }}
    </h4>
    <div
      class="list-item-content h-200px flex flex-row overflow-hidden border-rd-10px"
      :style="{
        borderColor: topChoice ? 'var(--tc-color-primary)' : '#D9D9D9',
        backgroundColor: topChoice ? 'var(--el-color-primary-light-9)' : '#fff',
      }"
    >
      <div position-relative h-200px w-200px>
        <img :src="itemData.attrImg" h-200px w-200px rounded-lb-2.5 object-cover />
        <div v-if="!hideRankNum" class="item-rank" :class="{ 'item-rank-top': isTop }" :style="rankStyle">
          <p v-if="!isTop">
            {{ itemIndex < 9 ? `0${itemIndex + 1}` : itemIndex + 1 }}
          </p>
        </div>
      </div>

      <div class="item-right" m="l-4 r-3.5" flex="~ 1 row" overflow-hidden>
        <!-- left title zone -->
        <div flex flex-1 flex-col overflow-hidden>
          <p text="2xl #121212" m="t-2.5" line-clamp-2 overflow-hidden text-ellipsis font-700>
            {{ itemData.attrName }}
          </p>

          <div m-t="2" min-h-20px flex="~ row items-center">
            <img v-if="itemData.address" :src="iconLocation" h-20px w-20px />
            <p class="color-[#888888]" m="l-1 r-3" overflow="hidden" text="ellipsis base" whitespace="nowrap" font="400">
              {{ itemData.address || '' }}
            </p>
          </div>

          <!-- star -->
          <div v-if="ratingDesc" m-t="2" flex flex-row items-center>
            <van-rate
              v-model="rating"
              readonly allow-half
              color="var(--tc-color-star)"
              void-color="#e3e3e3"
              size="18"
              gutter="2"
              void-icon="star"
            />
            <p
              :style="{ color: 'var(--tc-color-score)' }"
              m="l-2"
              text="base"
              font="700"
            >
              {{ ratingDesc }}
            </p>
          </div>
        </div>

        <!-- right price zone -->
        <div
          flex="~ col shrink-0 justify-center items-start"
          class="w-57.5 border-[#D9D9D9]" m="y-3.5" border-1px border-rd-15px border-solid p-15px
          :style="{ backgroundColor: '#fff' }"
        >
          <template v-if="isBurnCoinsScene">
            <div text="2xl" :style="{ color: 'var(--tc-color-view-button)' }" font="600" m="b-1">
              750,000
            </div>
            <div flex="~ row items-center" m="b-2.5">
              <img :src="iconGift" h="5" w="5" />
              <p text="sm #ABABAB ellipsis" m-l="1" overflow="hidden" font="500">
                {{ t('key.attractions.list.points.required') }}
              </p>
            </div>
            <div w="full" m="t-1" :style="{ backgroundColor: 'var(--tc-color-view-button)' }" h="12" cursor="pointer" border-rd-5px text-align-center font-size-20px font-600 line-height-48px @click.stop="clickItem">
              {{ t('key.attractions.list.redeem.now') }}
            </div>
          </template>
          <template v-else>
            <div v-if="itemData.cashbackDesc" flex="~ row" w="full" m="b-1.5">
              <img :src="iconGift" h="5" w="5" />
              <p :style="{ color: 'var(--tc-color-cashback-desc)' }" text="sm" m="l-1" overflow="hidden" whitespace="nowrap" font="500" class="truncate">
                {{ itemData.cashbackDesc }}
              </p>
            </div>
            <div text="sm #ABABAB" m="b-1">
              {{ t('key.list.sites', [itemData.websiteNum]) }}
            </div>
            <div text="xl #121212" font="600" m="b-2">
              {{ itemData.lowPrice ? priceText : t('key.attractions.list.see.price') }}
            </div>
            <div w="full" :style="{ backgroundColor: 'var(--tc-color-view-button)' }" h="12" cursor="pointer" border-rd-5px text="xl center" font="500" leading="12" @click.stop="clickItem">
              {{ itemData.lowPrice ? t("key.list.btnTitle") : t('key.list.getPrice') }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.list-item {
  border-radius: 10px;
  overflow: hidden;
  h4 {
    background-color: var(--tc-color-primary);
    padding-left: 12px;
    line-height: 32px;
  }
  .list-item-content {
    border: 1px solid #D9D9D9;
  }
    .item-rank {
      position: absolute;
      left: 10px;
      top: 0;
      width: 45px;
      height: 51px;

      > p {
        margin-top: 4px;
        width: 45px;
        color: #683815;
        text-align: center;
        font: {
          size: 24px;
          weight: 700;
        }
      }
    }

    .item-rank-top {
      width: 60px;
      height: 72px;
    }
}
</style>
