<script setup lang="ts">
import { useScrollParent } from '@vant/use'
import ListTop from './_components/ListTop.vue'
import SearchBar from './_components/SearchBar.vue'
import { useFilterConditionsStore } from '@/stores/filter-conditions'

import LogoG from '~/assets/images/list/logo_g.png'
import LogoKkday from '~/assets/images/list/logo_kkday.png'
import LogoKlook from '~/assets/images/list/logo_klook.png'
import LogoPe from '~/assets/images/list/logo_pe.png'
import LogoTrip from '~/assets/images/list/logo_trip.png'
import LogoViator from '~/assets/images/list/logo-viator.png'
import ogImageUrl from '~/assets/logo.png'

import { E_RANK_TYPE } from '~/enums'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.list.seo.title'),
  description: t('key.attractions.list.seo.description'),
  ogImageUrl,
})

const { clearSearch } = useSearchAttr()

const filterConditionsStore = useFilterConditionsStore()
const { tabList, currentTab, handleChangeTab, fetchTabList } = useAttrOrExperTabs()

fetchTabList()

const observerCom = useTemplateRef('observer-com')
const topEl = useTemplateRef('top-el')
const tabEl = useTemplateRef('tab-el')
const scrollParent = useScrollParent(observerCom as Ref<Element | undefined>)
const { height } = useElementBounding(topEl as unknown as Ref<HTMLElement>)
const { top } = useElementBounding(tabEl as unknown as Ref<HTMLElement>)
const { y } = useScroll(scrollParent as Ref<HTMLElement>)

const isScrollOver = computed(() => {
  return y.value > height.value
})

const isTabScrollOver = computed(() => {
  return top.value <= 108
})

watch(
  [
    () => filterConditionsStore.filterConditions.selectedCity,
  ],
  () => {
    clearSearch()
    navigateToList({ replace: true })
  },
)
</script>

<template>
  <div ref="observer-com" class="list">
    <div class="content">
      <ListTop ref="top-el"></ListTop>
      <div v-show="!isScrollOver" class="flex bg-white" overflow-x="auto" sticky top-0px>
        <div max="w-300" m="x-auto" class="flex flex-row flex-justify-around flex-items-center">
          <img v-for="(item, index) of [LogoKlook, LogoKkday, LogoPe, LogoG, LogoTrip, LogoViator]" :key="index" class="h-15" :src="item" />
        </div>
      </div>
      <div v-show="isScrollOver" sticky top-0px z-10 h-60px flex items-center bg-white>
        <div max="w-300" mx="auto" w="full">
          <SearchBar></SearchBar>
        </div>
      </div>
      <div v-show="isScrollOver && isTabScrollOver" class="top-26 shadow-[0_4px_13.4px_0px_rgba(132,132,132,0.25)]" h="16" fixed z-1 w-full bg-white></div>
      <div max="w-300" m="x-auto" color="white">
        <div ref="tab-el" class="tabs" sticky top-60px z-1000>
          <div class="tabs-content">
            <div class="tabs-content-item">
              <div
                v-for="(item) in tabList"
                :key="item.rankType"
                flex="1 ~ items-center justify-center"
                class="tabs-content-item-sub"
                :class="{ 'tabs-content-item-sub-high': currentTab === item.rankType }"
                @click="handleChangeTab(item.rankType || '')"
              >
                <span cursor="pointer" select="none">
                  <img v-if="item.rankType === E_RANK_TYPE.ALL" class="h-6 w-6 align-text-top" m="r-1" src="~/assets/icons/select-all.png" />
                  <img v-else-if="item.rankType === E_RANK_TYPE.ATTRACTIONS" class="h-6 w-6 align-text-top" m="r-1" src="~/assets/icons/ferris-wheel.png" />
                  <img v-else class="h-6 w-6 align-text-top" m="r-1" src="~/assets/icons/parachute-box-solid.png" />
                  <span>{{ item.rankTypeName }}</span>
                </span>
              </div>
            </div>
            <div v-show="!isScrollOver || !isTabScrollOver" class="tabs-content-line"></div>
          </div>
        </div>
        <DesktopTopAll v-if="currentTab === E_RANK_TYPE.ALL" />
        <DesktopTopAttractions v-if="currentTab === E_RANK_TYPE.ATTRACTIONS" />
        <DesktopTopExperiences v-if="currentTab === E_RANK_TYPE.EXPERIENCE" />
      </div>
    </div>
  </div>
</template>

<style  lang="scss">
body{
  background-color: white;
}
</style>

<style scoped lang="scss">
.list {
  width: 100%;
  height: 100%;
  background-color: white;

  .content {
    width: 100%;
    background-color: white;

    position: relative;

    // .filter {
    //   background-color: white;
      z-index: 1;

      .tabs {
        background-color: white;
        border-radius: 14px 14px 0 0;

        &-content {
          display: flex;
          flex-direction: column;

          &-line {
            width: 100%;
            height: 0.2px;
            background-color: #b7b7b7;
          }

          &-item {
            // margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 60px;
            overflow-x: scroll;

            // 隐藏滑动条
            &::-webkit-scrollbar {
              display: none;
            }

            &-sub {
              // margin: 0 10px;
              // margin-right: 50px;
              height: 60px;

              display: flex;
              text-align: center;
              position: relative;

              > span {
                line-height: 56px;
                color: #121212;
                padding: 0 4px;
                font: {
                  size: 20px;
                  weight: 400;
                }
                // 文本不允许换行
                white-space: nowrap;
              }

              &-high {
                > span {
                  font-weight: 700;
                  border-bottom: 3px solid #121212;

                }
              }

              &-line {
                width: 100%;
                height: 3px;
                background-color: #000000;

                position: absolute;
                left: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    // }
  }
}
</style>
