<template>
  <div class="flex">
    <div m-r="5" mt="4">
      <div b="1 solid #D9D9D9" w="70" pt="4" rounded-15px>
        <div pl="4" text="lg black" font="600" leading-22.5px>
          {{ t('key.attractions.list.filter') }}
        </div>
        <BusinessBFilter :is-click-trigger="true" />
      </div>
    </div>
    <div flex="1" overflow-x="auto">
      <Empty v-if="!data.list.loading && data.list.error" desc="" :show-btn="data.list.showErrorBtn" @empty-event="fetchRankList" />
      <ElSkeleton v-else :loading="data.list.loading" animated>
        <template #template>
          <div v-for="item in 5" :key="item" flex="~" m="y-4">
            <el-skeleton-item variant="image" style="width: 200px; height: 200px; border-radius: 10px;" />
            <div flex="1" p="y-1.5 x-3">
              <el-skeleton-item variant="caption" style="height: 42px;" />
              <el-skeleton-item variant="h1" class="my-2" style="width: 70%;" />
              <el-skeleton-item variant="p" style="width: 40%;" />
            </div>
            <div w="58" p="4">
              <el-skeleton-item variant="h1" />
              <el-skeleton-item variant="p" m="y-2" style="width: 70%;" />
              <el-skeleton-item variant="h1" style="width: 70%;" />
              <el-skeleton-item variant="button" class="my-2" style="width: 100%;" />
            </div>
          </div>
        </template>

        <div v-if="route.query.query" class="search-result">
          <p m="t-4 b-2.5" color="black">
            {{ t('key.attractions.list.search.result', [confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length || '0', route.query.query as string]) }}
          </p>

          <Empty v-if="confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length === 0" desc="" btn-txt="" />
          <DesktopTopAttractionsItem
            v-for="(item, index) of confirmAttrList[E_RANK_TYPE.ATTRACTIONS]"
            :key="index"
            :top-choice="index === 0"
            :item-index="index"
            :item-data="item"
            :hide-rank-num="true"
            m="y-4"
          />
          <h2 mt="8" color="#000">
            {{ t('key.list.other.recommendations') }}
          </h2>
        </div>
        <DesktopTopAttractionsItem
          v-for="(item, index) of data.list.items"
          :key="index"
          m="y-4"
          :item-index="index"
          :item-data="item"
        />
      </ElSkeleton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

const { t } = useI18n()
const route = useRoute()
const { data, fetchRankList, fetchSearchAttraction } = useAttractionList()
const { confirmAttrList } = useSearchAttr()

const filterConditionsStore = useFilterConditionsStore()
const userInfoStore = useUserStore()

watch(
  [
    () => filterConditionsStore.filterConditions.date,
    () => userInfoStore.userInfo.currency,
    () => filterConditionsStore.filterConditions.filterIdList,
    () => filterConditionsStore.filterConditions.range,
  ],
  () => {
    fetchRankList()
    fetchSearchAttraction()
  },
  {
    immediate: true,
  },
)
</script>

<style>

</style>
