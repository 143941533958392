<template>
  <div>
    <div v-if="!loading && !confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length && !confirmAttrList[E_RANK_TYPE.EXPERIENCE].length" p="y-4">
      <Empty desc="" :show-btn="true" @empty-event="fetchSearch" />
    </div>
    <ElSkeleton v-else :loading="loading" animated>
      <template #template>
        <div v-for="item in 5" :key="item" flex="~" m="y-4">
          <el-skeleton-item variant="image" style="width: 200px; height: 200px; border-radius: 10px;" />
          <div flex="1" p="y-1.5 x-3">
            <el-skeleton-item variant="caption" style="height: 42px;" />
            <el-skeleton-item variant="h1" class="my-2" style="width: 70%;" />
            <el-skeleton-item variant="p" style="width: 40%;" />
          </div>
          <div w="58" p="4">
            <el-skeleton-item variant="h1" />
            <el-skeleton-item variant="p" m="y-2" style="width: 70%;" />
            <el-skeleton-item variant="h1" style="width: 70%;" />
            <el-skeleton-item variant="button" class="my-2" style="width: 100%;" />
          </div>
        </div>
      </template>
      <p m="t-4 b-2.5" color="#000000">
        {{ t('key.attractions.list.search.result', [searchNum || '0', route.query.query as string]) }}
      </p>
      <DesktopTopAttractionsItem
        v-for="(item, index) of confirmAttrList[E_RANK_TYPE.ATTRACTIONS]"
        :key="index"
        :top-choice="index === 0"
        :item-index="index"
        :item-data="item"
        :hide-rank-num="true"
        m="y-4"
      />
      <DesktopTopExperiencesItem
        v-for="(item, index) of confirmAttrList[E_RANK_TYPE.EXPERIENCE]"
        :key="index"
        m="y-4"
        :top-choice="!confirmAttrList[E_RANK_TYPE.ATTRACTIONS].length && index === 0"
        :item-index="index"
        :item-data="item"
        :hide-rank-num="true"
      />
    </ElSkeleton>
  </div>
</template>

<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

const { t } = useI18n()
const route = useRoute()
const { confirmAttrList, doAllSearch, searchNum } = useSearchAttr()
const loading = ref(false)
const filterConditionsStore = useFilterConditionsStore()

function fetchSearch() {
  loading.value = true
  doAllSearch(route.query.query as string).then((searhResult) => {
    confirmAttrList.value = {
      [E_RANK_TYPE.ATTRACTIONS]: searhResult.rankAttList || [],
      [E_RANK_TYPE.EXPERIENCE]: searhResult.expSearchList || [],
    }
    loading.value = false
  })
}

watch(
  [() => route.query.query, () => filterConditionsStore.filterConditions.date],
  () => {
    if (route.query.query) {
      fetchSearch()
    }
  },
  {
    immediate: true,
  },
)
</script>

<style>

</style>
