<script setup lang="ts">
import iconRank_1 from '@/assets/images/list/icon_rank_top1.png'
import iconRank_2 from '@/assets/images/list/icon_rank_top2.png'
import iconRank_3 from '@/assets/images/list/icon_rank_top3.png'
import iconRank_4 from '@/assets/images/list/icon_rank_top4.png'
import iconGift from '@/assets/images/list/icon_gift.png'
import { useCommonStore } from '@/stores/common'
import { toThousands } from '@/utils'
import type { AttSpecialTopicConfigDTO } from '~/apis/back/types'

interface Props {
  itemIndex: number
  itemData: AttSpecialTopicConfigDTO
  hideRankNum?: boolean
  topChoice?: boolean

}

const props = defineProps<Props>()
const commonDataStore = useCommonStore()
const { t } = useI18n()

const { isBurnCoinsScene } = useGlobalStore()
const { navigatorToExperienceDetail } = useExperienceList()

const priceSymbol = commonDataStore.getCurrencySymbol
const isTop = computed(() => {
  return props.itemIndex < 3
})

const rangImg = computed(() => {
  switch (props.itemIndex) {
    case 0:
      return iconRank_1
    case 1:
      return iconRank_2
    case 2:
      return iconRank_3
    default:
      return iconRank_4
  }
})

const rankStyle = computed(() => {
  return {
    backgroundImage: `url(${rangImg.value})`,
    backgroundSize: '100% 100%',
  }
})

const priceText = computed(() => {
  return priceSymbol + toThousands(props.itemData.minPrice!)
})

function clickItem() {
  navigatorToExperienceDetail(props, !props.hideRankNum)
}
</script>

<template>
  <div class="list-item" :style="topChoice ? { backgroundColor: 'var(--tc-color-primary)' } : null">
    <div v-if="topChoice" p="x-4 y-1" text="lg" font="600">
      {{ t('key.search.list.top.choice') }}
    </div>
    <div
      min-h="50"
      class="list-item-content flex flex-row overflow-hidden border-rd-10px"
      :style="{
        borderColor: topChoice ? 'var(--tc-color-primary)' : '#D9D9D9',
        backgroundColor: topChoice ? 'var(--el-color-primary-light-9)' : '#fff',
      }"
    >
      <div position-relative h-200px w-200px>
        <img h-200px w-200px rounded-lb-2.5 object-cover :src="itemData.cover" />
        <div v-if="!hideRankNum" class="item-rank" :class="{ 'item-rank-top': isTop }" :style="rankStyle">
          <p v-if="!isTop">
            {{ itemIndex < 9 ? `0${itemIndex + 1}` : itemIndex + 1 }}
          </p>
        </div>
      </div>

      <div class="item-right" m-r-2.5 flex flex-1 flex-row overflow-hidden p-x-0>
        <!-- left title zone -->
        <div flex="~ 1 col" m="r-4 l-4 t-2.5" overflow="hidden">
          <p class="color-[#121212]" text="2xl" line-clamp-2 overflow-hidden text-ellipsis font-700>
            {{ itemData.specialTopicName }}
          </p>
          <div v-if="itemData.intro" mt="2.5" class="relative">
            <img :width="12" class="absolute top-1" src="~/assets/images/list/intro.png" />
            <van-text-ellipsis text="black xs" :content="`     ${itemData.intro}`" :rows="3">
              <template #action="{ expanded }">
                <div flex="~ items-center">
                  <span class="mr-1 font-400 underline" text="xs black" font="500">
                    {{ expanded ? t('key.experience.read.less') : t('key.experience.read.more') }}
                  </span>
                  <img
                    src="~assets/svg/arrow-down.svg"
                    :width="10"
                    :class="expanded ? 'rotate-180 mt-1' : 'mt-1'"
                  />
                </div>
              </template>
            </van-text-ellipsis>
          </div>
        </div>

        <!-- right price zone -->
        <div
          flex="~ col shrink-0 justify-center items-start"
          class="w-57.5 border-[#D9D9D9]" m="y-3.5" m-r-2px border-1px border-rd-15px border-solid p-15px
          :style="{ backgroundColor: '#fff' }"
        >
          <template v-if="isBurnCoinsScene">
            <div text="3xl" :style="{ color: 'var(--tc-color-view-button)' }" font="600" m="b-1">
              750,000
            </div>
            <div flex="~ row items-center" m="b-2.5">
              <img :src="iconGift" h="6" w="6" />
              <p text="sm #ABABAB ellipsis" m-l="1" overflow="hidden" font="500">
                {{ t('key.attractions.list.points.required') }}
              </p>
            </div>
            <div w="full" m="t-1" :style="{ backgroundColor: 'var(--tc-color-view-button)' }" h="12" cursor="pointer" border-rd-5px text-align-center font-size-20px font-600 line-height-48px @click.stop="clickItem">
              {{ t('key.attractions.list.redeem.now') }}
            </div>
          </template>
          <template v-else>
            <div v-if="itemData.cashbackDesc" flex="~ row" w="full" m="b-1.5">
              <img :src="iconGift" h="5" w="5" />
              <p :style="{ color: 'var(--tc-color-cashback-desc)' }" text="sm" m="l-1" overflow="hidden" whitespace="nowrap" font="500" class="truncate">
                {{ itemData.cashbackDesc }}
              </p>
            </div>
            <div text="sm #ABABAB">
              {{ t('key.list.sites', [itemData.websiteNum]) }}
            </div>
            <div text="xl #121212" font="600">
              {{ itemData.minPrice ? priceText : t('key.attractions.list.see.price') }}
            </div>
            <div w="full" m="t-1" :style="{ backgroundColor: 'var(--tc-color-view-button)' }" h="12" cursor="pointer" border-rd-5px text="xl center" font="500" leading="12" @click.stop="clickItem">
              {{ itemData.minPrice ? t("key.list.btnTitle") : t('key.list.getPrice') }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.list-item {
  border-radius: 10px;
  overflow: hidden;
  h4 {
    background-color: var(--tc-color-primary);
    padding-left: 12px;
    line-height: 32px;
  }
  .list-item-content {
    border: 1px solid #D9D9D9;
  }
    .item-rank {
      position: absolute;
      left: 10px;
      top: 0;
      width: 45px;
      height: 51px;

      > p {
        margin-top: 4px;
        width: 45px;
        color: #683815;
        text-align: center;
        font: {
          size: 24px;
          weight: 700;
        }
      }
    }

    .item-rank-top {
      width: 60px;
      height: 72px;
    }
}
</style>
